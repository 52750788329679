import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, Tag } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import api from "../../services/api";
import EditPostModal from "./EditPostModal";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedPost, setEditedPost] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const getPosts = async (pgn) => {
    setLoading(true);
    const resp = await api.get(
      `/api/posts?page=${pgn.current}&limit=${pgn.pageSize}`
    );
    setLoading(false);
    setPosts(resp.data.posts);
    setPagination(
      produce((draft) => {
        draft.total = resp.data.total;
      })
    );
  };

  useEffect(() => {
    getPosts(pagination);
  }, []);

  const onPaginationChange = (pgn) => {
    setPagination(pgn);
    getPosts(pgn);
  };

  const handleOk = async (post, images, tags) => {
    post.images = images.join(",");
    post.tags = tags ? tags.join(",") : "";
    let newPosts = [];
    if (!post.uuid) {
      // create post
      const resp = await api.post("/api/posts", post);
      newPosts = [...posts, resp.data];
      setPagination(
        produce((draft) => {
          draft.total = pagination.total + 1;
        })
      );
    } else {
      // update post
      await api.put(`/api/posts/${editedPost.uuid}`, post);
      newPosts = posts.map((pst) => {
        if (pst.uuid === editedPost.uuid) {
          pst = { ...pst, ...post };
        }
        return pst;
      });
    }
    setPosts(newPosts);
    setIsModalVisible(false);
  };

  const handleDelete = async (post) => {
    await api.delete(`/api/posts/${post.uuid}`, post);
    setPosts(posts.filter((pst) => pst.uuid !== post.uuid));
    setPagination(
      produce((draft) => {
        draft.total = pagination.total - 1;
      })
    );
  };

  const toggleDisabled = async (post) => {
    post.disabled = !post.disabled;
    await api.put(`/api/posts/${post.uuid}`, post);
    setPosts(
      posts.map((pst) => {
        if (pst.uuid === post.uuid) {
          pst.disabled = post.disabled;
        }
        return pst;
      })
    );
  };

  const columns = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "RolesTags",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.split(",").map((tag) => {
            return (
              <Tag color="geekblue" key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "FrontPage",
      key: "frontPage",
      dataIndex: "frontPage",
      render: (frontPage) => <span>{frontPage ? "true" : "false"}</span>,
    },
    {
      title: "Disabled",
      key: "disabled",
      dataIndex: "disabled",
      render: (disabled) => <span>{disabled ? "true" : "false"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <span></span>

          <Button
            type="link"
            size="small"
            icon={<CloseCircleOutlined />}
            onClick={() => toggleDisabled(record)}
          />

          <Popconfirm
            title="Are you sure to delete this post?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>

          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedPost(record);
              setIsModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>Posts</h1>
      <EditPostModal
        item={editedPost}
        modalTitle="Edit Post"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <Row justify="end">
        <Button
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => {
            setEditedPost({
              title: "",
              text: "",
              images: "",
              video: "",
              tags: "",
              category: "blog",
              frontPage: false,
              disabled: false,
            });
            setIsModalVisible(true);
          }}
        >
          New Post
        </Button>
      </Row>
      <Table
        columns={columns}
        rowKey="uuid"
        pagination={pagination}
        loading={loading}
        onChange={onPaginationChange}
        dataSource={posts}
      />
    </>
  );
};

export default Posts;
