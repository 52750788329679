import Aliment from "./Aliment";
import ReactMarkdown from "react-markdown";
const Meal = ({ meal }) => {
  return (
    <>
      <ReactMarkdown>{meal.note}</ReactMarkdown>
      {meal.aliments.map((aliment) => (
        <Aliment aliment={aliment} key={`aliment${aliment.id}`} />
      ))}
    </>
  );
};

export default Meal;
