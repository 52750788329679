import axios from "axios";
import jwt_decode from "jwt-decode";

const instance = axios.create({
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const setTokens = (token, refresh, save = false) => {
  instance.token = token;
  instance.refresh = refresh;
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (save) {
      localStorage.setItem("authToken", token);
      localStorage.setItem("authRefresh", refresh);
    }
  }
};

const loadFromLocalStorage = () => {
  let token = localStorage.getItem("authToken");
  let refresh = localStorage.getItem("authRefresh");
  if (token) {
    setTokens(token, refresh);
  }
};

loadFromLocalStorage();

export const getToken = () => instance.token;

export const clearTokens = () => {
  instance.token = null;
  instance.refresh = null;
  instance.defaults.headers.common["Authorization"] = "";
  localStorage.removeItem("authToken");
  localStorage.removeItem("authRefresh");
};

instance.interceptors.request.use(async (req) => {
  if (instance.lock) {
    return req;
  }
  instance.lock = true;
  if (!instance.token || req.url === "/api/auth/logout") {
    instance.lock = false;
    return req;
  }
  const user = jwt_decode(instance.token);
  const isExpired = user.exp - Math.floor(Date.now() / 1000) <= 0;
  //console.log(isExpired, user.exp - Math.floor(Date.now() / 1000));

  if (!isExpired) {
    instance.lock = false;
    return req;
  }

  try {
    const resp = await axios.post(`/api/auth/refresh`, {
      refresh: instance.refresh,
    });
    const { token, refresh } = resp.data;
    req.headers.Authorization = `Bearer ${token}`;
    setTokens(token, refresh, true);
  } catch (err) {
    console.log("refresh err: ", err);
    clearTokens();
  }

  instance.lock = false;
  return req;
});

export default instance;
