import {
  FileOutlined,
  PieChartOutlined,
  GiftOutlined,
  ExperimentOutlined,
  CalculatorOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { Input, InputNumber, Modal, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesAsync } from "./dietsSlice";

const EditAlimentModal = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const categories = useSelector((state) => state.diets.categories);
  const dispatch = useDispatch();
  const [aliment, setAliment] = useState({});

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
    if (item) {
      setAliment({ ...item });
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(aliment)}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Input
          style={{ width: "100%" }}
          addonBefore="Name"
          addonAfter={<FileOutlined />}
          placeholder="name"
          value={aliment.name}
          onChange={(e) => {
            setAliment({ ...aliment, name: e.target.value });
          }}
        />
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Quantity"
          addonAfter={<PieChartOutlined />}
          placeholder="quantity"
          step={10}
          min={0}
          value={aliment.quantity}
          onChange={(newValue) => {
            setAliment({ ...aliment, quantity: newValue });
          }}
        />
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Calories"
          addonAfter={<GiftOutlined />}
          placeholder="calories"
          step={10}
          min={0}
          value={aliment.calories}
          onChange={(newValue) => {
            setAliment({ ...aliment, calories: newValue });
          }}
        />
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Carbs"
          addonAfter={<ExperimentOutlined />}
          placeholder="carbs"
          step={10}
          min={0}
          value={aliment.carbs}
          onChange={(newValue) => {
            setAliment({ ...aliment, carbs: newValue });
          }}
        />
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Proteins"
          addonAfter={<CalculatorOutlined />}
          placeholder="proteins"
          step={10}
          min={0}
          value={aliment.proteins}
          onChange={(newValue) => {
            setAliment({ ...aliment, proteins: newValue });
          }}
        />
        <InputNumber
          style={{ width: "100%" }}
          addonBefore="Fats"
          addonAfter={<SlidersOutlined />}
          placeholder="fats"
          step={10}
          min={0}
          value={aliment.fats}
          onChange={(newValue) => {
            setAliment({ ...aliment, fats: newValue });
          }}
        />
        <Select
          style={{ width: "100%" }}
          placeholder="Please select category"
          defaultActiveFirstOption
          value={aliment.categoryId}
          onChange={(newValue) => {
            setAliment({ ...aliment, categoryId: newValue });
          }}
        >
          {categories.map((cat) => (
            <Select.Option key={cat.id} value={cat.id}>
              {cat.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Modal>
  );
};
export default EditAlimentModal;
