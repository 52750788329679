import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginAsync } from "./authSlice";
import "./Login.css";

const Login = ({ kind }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const navigate = useNavigate();

  const alternative = kind === "Login" ? "register" : "login";

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);
  const onFinish = (values) => {
    values.kind = kind;
    dispatch(loginAsync(values));
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Form
        name="normal_login"
        style={{ maxWidth: 300 }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="password"
          />
        </Form.Item>
        {kind === "Register" && (
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="check password"
            />
          </Form.Item>
        )}
        {kind === "Login" && (
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link
              style={{
                float: "right",
              }}
              to="/forgot"
            >
              Forgot password
            </Link>
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {kind}
          </Button>
          <span> Or</span>{" "}
          <Link to={`/${alternative}`}>{`${alternative} now!`}</Link>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Login;
