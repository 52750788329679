import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const RoleCheckWrapper = ({ expectedRole, children, messageElement }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  return (
    <>
      {user
        ? !user.disabled && user.roles.includes(expectedRole)
          ? children
          : messageElement
        : messageElement && <Navigate to="/login" state={{ from: location }} />}
    </>
  );
};

export default RoleCheckWrapper;
