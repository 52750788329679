import {
  MenuUnfoldOutlined,
  SortAscendingOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";
const EditNameDescriptionModal = ({
  modalTitle,
  item,
  visible,
  onCancel,
  onOk,
}) => {
  const [obj, setObj] = useState({});

  useEffect(() => {
    if (item) {
      setObj({ ...item });
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(obj)}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Input
          prefix={<SortAscendingOutlined />}
          placeholder="name"
          value={obj.name}
          onChange={(e) => {
            setObj({ ...obj, name: e.target.value });
          }}
        />
        <Input
          prefix={<MenuUnfoldOutlined />}
          placeholder="description"
          value={obj.description}
          onChange={(e) => {
            setObj({ ...obj, description: e.target.value });
          }}
        />
        <Input
          prefix={<GiftOutlined />}
          placeholder="calories"
          value={obj.calories}
          onChange={(e) => {
            setObj({ ...obj, calories: Number(e.target.value) });
          }}
        />
      </Space>
    </Modal>
  );
};
export default EditNameDescriptionModal;
