import { SortAscendingOutlined } from "@ant-design/icons";
import { Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";
const EditNameModal = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const [obj, setObj] = useState({});

  useEffect(() => {
    if (item) {
      setObj({ ...item });
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(obj)}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Input
          prefix={<SortAscendingOutlined />}
          placeholder="name"
          value={obj.name}
          onChange={(e) => {
            setObj({ ...obj, name: e.target.value });
          }}
        />
      </Space>
    </Modal>
  );
};
export default EditNameModal;
