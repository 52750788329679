import { MailOutlined } from "@ant-design/icons";
import { Input, Modal, Space, Select } from "antd";
import { useEffect, useState } from "react";
const EditUserModal = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (item) {
      setUser({ ...item });
      setRoles(item.roles ? item.roles.split(",") : []);
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(user, roles)}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Input
          prefix={<MailOutlined />}
          placeholder="email"
          value={user.email}
          onChange={(e) => {
            setUser({ ...user, email: e.target.value });
          }}
        />
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Please add roles"
          value={roles}
          onChange={(rls) => setRoles(rls)}
        />
      </Space>
    </Modal>
  );
};
export default EditUserModal;
