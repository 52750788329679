import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Post from "./Post";
import "./PostCarousel.css";

const PostCarousel = ({ title, category }) => {
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    const resp = await api.get(
      `/api/posts/read?front=true&disabled=false&category=${category}`
    );
    setPosts(resp.data.posts);
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <h2>{title}</h2>
      <Carousel
        arrows
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
      >
        {posts.map((p) => (
          <div key={p.uuid}>
            <Post post={p} />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default PostCarousel;
