import { createAsyncThunk, createSlice, isPending } from "@reduxjs/toolkit";
import api from "../../services/api";

const initialState = {
  loading: false,
  day: null,
  meal: null,
  categories: [],
  categoriesCount: 0,
  aliments: [],
  alimentsCount: 0,
  diets: [],
  bestDiet: null,
  dietsCount: 0,
};

// CATEGORIES
export const fetchCategoriesAsync = createAsyncThunk(
  "diets/fetchCategories",
  async (data) => {
    let resp = null;
    if (data) {
      const { pagination, search } = data;
      resp = await api.get(
        `/api/categories?page=${pagination.current}&limit=${pagination.pageSize}&search=${search}`
      );
    } else {
      resp = await api.get(`/api/categories`);
    }
    return resp.data;
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "diets/deleteCategory",
  async (category) => {
    await api.delete(`/api/categories/${category.id}`, category);
    return category.id;
  }
);

export const createCategoryAsync = createAsyncThunk(
  "diets/createCategory",
  async (category) => {
    const resp = await api.post("/api/categories", category);
    return resp.data;
  }
);

export const updateCategoryAsync = createAsyncThunk(
  "diets/updateCategory",
  async (category) => {
    await api.put(`/api/categories/${category.id}`, category);
    return category;
  }
);

// ALIMENTS
export const fetchAlimentsAsync = createAsyncThunk(
  "diets/fetchAliments",
  async (data) => {
    let resp = null;
    if (data) {
      const { pagination, search, categoryId } = data;
      resp = await api.get(
        `/api/aliments?page=${pagination.current}&limit=${
          pagination.pageSize
        }&search=${search}&categoryId=${categoryId || ""}`
      );
    } else {
      resp = await api.get(`/api/aliments`);
    }
    return resp.data;
  }
);

export const deleteAlimentAsync = createAsyncThunk(
  "diets/deleteAliment",
  async (aliment) => {
    await api.delete(`/api/aliments/${aliment.id}`, aliment);
    return aliment.id;
  }
);

export const createAlimentAsync = createAsyncThunk(
  "diets/createAliment",
  async (aliment) => {
    const resp = await api.post("/api/aliments", aliment);
    return resp.data;
  }
);

export const updateAlimentAsync = createAsyncThunk(
  "diets/updateAliment",
  async (aliment) => {
    await api.put(`/api/aliments/${aliment.id}`, aliment);
    return aliment;
  }
);

// DIETS
export const fetchDietsAsync = createAsyncThunk(
  "diets/fetchDiets",
  async (pgn) => {
    const resp = await api.get(
      `/api/diets?page=${pgn.current}&limit=${pgn.pageSize}`
    );
    return resp.data;
  }
);

export const fetchBestDietAsync = createAsyncThunk(
  "diets/fetchBestDiet",
  async (data) => {
    const { category, calories } = data;
    const resp = await api.get(
      `/api/diets/bestdiet?category=${category}&calories=${calories}`
    );
    return resp.data;
  }
);

export const deleteDietAsync = createAsyncThunk(
  "diets/deleteDiet",
  async (diet) => {
    await api.delete(`/api/diets/${diet.id}`, diet);
    return diet.id;
  }
);

export const createDietAsync = createAsyncThunk(
  "diets/createDiet",
  async (diet) => {
    const resp = await api.post("/api/diets", diet);
    return resp.data;
  }
);

export const updateDietAsync = createAsyncThunk(
  "diets/updateDiet",
  async (diet) => {
    await api.put(`/api/diets/${diet.id}`, diet);
    return diet;
  }
);

export const dietsSlice = createSlice({
  name: "diets",
  initialState,
  reducers: {
    copyDay: (state, action) => {
      state.day = action.payload;
    },
    copyMeal: (state, action) => {
      state.meal = action.payload;
    },
  },
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload.categories;
        state.categoriesCount = action.payload.total;
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = state.categories.filter(
          (alm) => alm.id !== action.payload
        );
        state.categoriesCount -= 1;
      })
      .addCase(createCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories.push(action.payload);
        state.categoriesCount += 1;
      })
      .addCase(updateCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = state.categories.map((alm) => {
          if (alm.id === action.payload.id) {
            alm = { ...alm, ...action.payload };
          }
          return alm;
        });
      })
      .addCase(fetchAlimentsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.aliments = action.payload.aliments;
        state.alimentsCount = action.payload.total;
      })
      .addCase(deleteAlimentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.aliments = state.aliments.filter(
          (alm) => alm.id !== action.payload
        );
        state.alimentsCount -= 1;
      })
      .addCase(createAlimentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.aliments.push(action.payload);
        state.alimentsCount += 1;
      })
      .addCase(updateAlimentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.aliments = state.aliments.map((alm) => {
          if (alm.id === action.payload.id) {
            alm = { ...alm, ...action.payload };
          }
          return alm;
        });
      })
      .addCase(fetchDietsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.diets = action.payload.diets;
        state.dietsCount = action.payload.total;
      })
      .addCase(fetchBestDietAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.bestDiet = action.payload.diet;
      })
      .addCase(deleteDietAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.diets = state.diets.filter((alm) => alm.id !== action.payload);
        state.dietsCount -= 1;
      })
      .addCase(createDietAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.diets.push(action.payload);
        state.dietsCount += 1;
      })
      .addCase(updateDietAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.diets = state.diets.map((alm) => {
          if (alm.id === action.payload.id) {
            alm = { ...alm, ...action.payload };
          }
          return alm;
        });
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = true;
      });
  },
});

export const { copyDay, copyMeal } = dietsSlice.actions;

export default dietsSlice.reducer;
