import Meal from "./Meal";
import { Card, Typography, Collapse } from "antd";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const Day = ({ day }) => {
  return (
    <>
      <Card
        title={
          <>
            <Title level={4}>
              {day.name} ({day.calories})
            </Title>
            <Text type="secondary">{day.description}</Text>
          </>
        }
        extra={<a href="#">More</a>}
        style={{ width: 400 }}
      >
        <ul>
          <li>calories: {day.calories}</li>
          <li>proteins: {day.proteins}</li>
          <li>carbs: {day.carbs}</li>
          <li>fats: {day.fats}</li>
        </ul>

        <Collapse activeKey={day.meals.map((meal) => `meal${meal.id}`)}>
          {day.meals.map((meal) => (
            <Panel header={meal.name} key={`meal${meal.id}`}>
              <Meal meal={meal} />
            </Panel>
          ))}
        </Collapse>
      </Card>
    </>
  );
};

export default Day;
