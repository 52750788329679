import { useSelector } from "react-redux";
import Day from "./Day";
import { Button, Row } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import html2pdf from "html2pdf.js";
import ReactMarkdown from "react-markdown";

const MyDiet = () => {
  const bestDiet = useSelector((state) => state.diets.bestDiet);

  function savePdf() {
    let element = document.getElementById("mydiet");
    html2pdf(element);
  }
  return (
    <>
      <div id="mydiet">
        <h1>
          My Diet: {bestDiet.name} {bestDiet.calories}
        </h1>
        <Button
          type="primary"
          title="Save current diet to PDF"
          key="savePdf"
          icon={<FilePdfOutlined />}
          onClick={savePdf}
        >
          Save to PDF
        </Button>
        <h4>{bestDiet.description}</h4>
        <ReactMarkdown>{bestDiet.note}</ReactMarkdown>

        <Row wrap>
          {bestDiet.days.map((day) => (
            <Day day={day} key={`day${day.id}`} />
          ))}
        </Row>
      </div>
    </>
  );
};

export default MyDiet;
