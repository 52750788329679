import { Button, Col, Image, Menu, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutAsync } from "../auth/authSlice";
import Profile from "../profile/Profile";

const Navbar = () => {
  const navigate = useNavigate();
  const { user, status } = useSelector((state) => state.auth);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleLogoutClick = () => {
    dispatch(logoutAsync());
    navigate("/login");
  };

  const handleOk = async (profile) => {
    setIsProfileVisible(false);
  };

  return (
    <>
      <Profile
        item={user}
        modalTitle="Edit Profile"
        visible={isProfileVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsProfileVisible(false);
        }}
      />
      <Row justify="space-between">
        <Col xs={0} md={1}>
          <Image height={60} src="./logo.png" preview={false} />
        </Col>
        <Col xs={17}>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="main1">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="main2">
              <Link to="/blog">Blog</Link>
            </Menu.Item>
            <Menu.Item key="main3">
              <Link to="/mydiet">My Diet</Link>
            </Menu.Item>
            <Menu.Item key="main4">
              <Link to="/counter">Challange</Link>
            </Menu.Item>
            <Menu.Item key="main5">
              <Link to="/counter">Produse</Link>
            </Menu.Item>
            <Menu.Item key="main6">
              <Link to="/about">Despre noi</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={7} md={6}>
          <Row justify="end">
            <Col
              xs={0}
              sm={16}
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              {status === "loading" && <span>{status}...</span>}
              {user && (
                <a
                  onClick={() => {
                    setIsProfileVisible(true);
                  }}
                >
                  {user.email}
                </a>
              )}
            </Col>
            <Col xs={24} sm={8}>
              {user ? (
                <Button onClick={handleLogoutClick} type="link">
                  Logout
                </Button>
              ) : (
                <>
                  <Button onClick={handleRegisterClick} type="link">
                    Register
                  </Button>
                  <Button onClick={handleLoginClick} type="link">
                    Login
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Navbar;
