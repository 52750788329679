import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Row, Popconfirm, Space, Table, Input, Modal } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoriesAsync,
  deleteCategoryAsync,
  createCategoryAsync,
  updateCategoryAsync,
} from "./dietsSlice";
import EditNameModal from "./EditNameModal";
import Aliments from "./Aliments";

const { Search } = Input;

const Categories = () => {
  const loading = useSelector((state) => state.diets.loading);
  const categoriesCount = useSelector((state) => state.diets.categoriesCount);
  const categories = useSelector((state) => state.diets.categories);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedCategory, setEditedCategory] = useState(null);
  const [isAlimentsModalVisible, setIsAlimentsModalVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setPagination(
      produce((draft) => {
        draft.total = categoriesCount;
      })
    );
  }, [categoriesCount]);

  useEffect(() => {
    dispatch(fetchCategoriesAsync({ pagination, search }));
  }, []);

  const onPaginationChange = (pgn) => {
    setPagination(pgn);
    dispatch(fetchCategoriesAsync({ pagination: pgn, search }));
  };

  const onSearchChange = (srch) => {
    setSearch(srch);
    dispatch(fetchCategoriesAsync({ pagination, search: srch }));
  };

  const handleOk = async (category) => {
    if (!category.id) {
      // create category
      dispatch(createCategoryAsync(category));
    } else {
      // update category
      dispatch(updateCategoryAsync(category));
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (category) => {
    dispatch(deleteCategoryAsync(category));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <span></span>

          <Popconfirm
            title="Are you sure to delete this category?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
          <Button
            type="link"
            size="small"
            icon={<PieChartOutlined />}
            onClick={() => {
              setEditedCategory(record);
              setIsAlimentsModalVisible(true);
            }}
          />
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedCategory(record);
              setIsModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>Categories</h1>
      <EditNameModal
        item={editedCategory}
        modalTitle="Edit Category"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <Modal
        title="Aliments"
        width="400"
        open={isAlimentsModalVisible}
        maskClosable={false}
        onCancel={() => setIsAlimentsModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="cancel"
            onClick={() => setIsAlimentsModalVisible(false)}
          >
            Close
          </Button>,
        ]}
        getContainer={false}
      >
        <Aliments categoryId={editedCategory?.id} />
      </Modal>
      <Row justify="end">
        <Space>
          <Search
            placeholder="search categories"
            onSearch={onSearchChange}
            style={{
              width: 200,
            }}
          />
          <Button
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => {
              setEditedCategory({
                name: "",
              });
              setIsModalVisible(true);
            }}
          >
            New Category
          </Button>
        </Space>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={onPaginationChange}
        dataSource={categories}
      />
    </>
  );
};

export default Categories;
