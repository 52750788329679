import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Button, Row, Popconfirm, Space, Table, Input } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAlimentsAsync,
  deleteAlimentAsync,
  createAlimentAsync,
  updateAlimentAsync,
} from "./dietsSlice";
import EditAlimentModal from "./EditAlimentModal";

const { Search } = Input;

const Aliments = ({ categoryId }) => {
  const loading = useSelector((state) => state.diets.loading);
  const alimentsCount = useSelector((state) => state.diets.alimentsCount);
  const aliments = useSelector((state) => state.diets.aliments);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedAliment, setEditedAliment] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setPagination(
      produce((draft) => {
        draft.total = alimentsCount;
      })
    );
  }, [alimentsCount]);

  useEffect(() => {
    dispatch(
      fetchAlimentsAsync({
        pagination: { current: 1, pageSize: 10 },
        search: "",
        categoryId,
      })
    );
  }, [categoryId]);

  const onPaginationChange = (pgn) => {
    setPagination(pgn);
    dispatch(fetchAlimentsAsync({ pagination: pgn, search, categoryId }));
  };

  const onSearchChange = (srch) => {
    setSearch(srch);
    dispatch(fetchAlimentsAsync({ pagination, search: srch, categoryId }));
  };

  const handleOk = async (aliment) => {
    if (!aliment.id) {
      // create aliment
      dispatch(createAlimentAsync(aliment));
    } else {
      // update aliment
      dispatch(updateAlimentAsync(aliment));
    }
    setIsModalVisible(false);
  };

  const handleDelete = async (aliment) => {
    dispatch(deleteAlimentAsync(aliment));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "Calories",
      key: "calories",
      dataIndex: "calories",
    },
    {
      title: "Carbs",
      key: "carbs",
      dataIndex: "carbs",
    },
    {
      title: "Proteins",
      key: "proteins",
      dataIndex: "proteins",
    },
    {
      title: "Fats",
      key: "fats",
      dataIndex: "fats",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      render: (text, record) => {
        return text ? text.name : "";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <span></span>

          <Popconfirm
            title="Are you sure to delete this aliment?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>

          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedAliment(record);
              setIsModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>Aliments</h1>
      <EditAlimentModal
        item={editedAliment}
        modalTitle="Edit Aliment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <Row justify="end">
        <Space>
          <Search
            placeholder="search aliments"
            onSearch={onSearchChange}
            style={{
              width: 200,
            }}
          />
          <Button
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => {
              setEditedAliment({
                name: "",
                quantity: 100,
                calories: "",
                carbs: "",
                proteins: "",
                fats: "",
                categoryId: 0,
              });
              setIsModalVisible(true);
            }}
          >
            New Aliment
          </Button>
        </Space>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={onPaginationChange}
        dataSource={aliments}
      />
    </>
  );
};

export default Aliments;
