import { SortAscendingOutlined } from "@ant-design/icons";
import { Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";

const { TextArea } = Input;

const EditNoteModal = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const [obj, setObj] = useState({});

  useEffect(() => {
    if (item) {
      setObj({ ...item });
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(obj)}
      onCancel={onCancel}
    >
      <TextArea
        placeholder="note"
        value={obj.note}
        rows={10}
        onChange={(e) => {
          setObj({ ...obj, note: e.target.value });
        }}
      />
    </Modal>
  );
};
export default EditNoteModal;
