import {
  CoffeeOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useState } from "react";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./features/about/About";
import Login from "./features/auth/Login";
import RoleCheckWrapper from "./features/auth/RoleCheckWrapper";
import Blog from "./features/blog/Blog";
import Posts from "./features/blog/Posts";
import { Counter } from "./features/counter/Counter";
import Aliments from "./features/diets/Aliments";
import Categories from "./features/diets/Categories";
import Diets from "./features/diets/Diets";
import MyDiet from "./features/diets/mydiet/MyDiet";
import Home from "./features/home/Home";
import Navbar from "./features/home/Navbar";
import NotFound from "./features/home/NotFound";
import Users from "./features/users/Users";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const App = () => {
  const [collapsed, setCollapsed] = useState(true);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Router>
        <RoleCheckWrapper expectedRole="admin">
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo" />
            <Menu theme="dark" mode="inline">
              <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>

              <Menu.Item key="2" icon={<ProfileOutlined />}>
                <Link to="/posts">Blog</Link>
              </Menu.Item>
              <SubMenu key="sub1" icon={<CoffeeOutlined />} title="Diets">
                <Menu.Item key="3">
                  <Link to="/aliments">Aliments</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/diets">Diets</Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/categories">Categories</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
        </RoleCheckWrapper>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Navbar />
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/mydiet" element={<MyDiet />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login kind="Login" />} />
              <Route path="/register" element={<Login kind="Register" />} />
              <Route path="/counter" element={<Counter />} />

              <Route
                path="/users"
                element={
                  <RoleCheckWrapper
                    expectedRole="admin"
                    messageElement={<p>Insufficent premissions!</p>}
                  >
                    <Users />
                  </RoleCheckWrapper>
                }
              />
              <Route
                expectedRole="admin"
                path="/posts"
                element={
                  <RoleCheckWrapper
                    expectedRole="admin"
                    messageElement={<p>Insufficent premissions!</p>}
                  >
                    <Posts />
                  </RoleCheckWrapper>
                }
              />

              <Route
                expectedRole="admin"
                path="/aliments"
                element={
                  <RoleCheckWrapper
                    expectedRole="admin"
                    messageElement={<p>Insufficent premissions!</p>}
                  >
                    <Aliments />
                  </RoleCheckWrapper>
                }
              />
              <Route
                expectedRole="admin"
                path="/categories"
                element={
                  <RoleCheckWrapper
                    expectedRole="admin"
                    messageElement={<p>Insufficent premissions!</p>}
                  >
                    <Categories />
                  </RoleCheckWrapper>
                }
              />
              <Route
                expectedRole="admin"
                path="/diets"
                element={
                  <RoleCheckWrapper
                    expectedRole="admin"
                    messageElement={<p>Insufficent premissions!</p>}
                  >
                    <Diets />
                  </RoleCheckWrapper>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center" }}>Venus Project ©2023</Footer>
        </Layout>
      </Router>
    </Layout>
  );
};

export default App;
