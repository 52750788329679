import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Profile from "../profile/Profile";
import EditUserModal from "./EditUserModal";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [editedUser, setEditedUser] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const getUsers = async (pgn) => {
    setLoading(true);
    const resp = await api.get(
      `/api/users?page=${pgn.current}&limit=${pgn.pageSize}`
    );
    setLoading(false);
    setUsers(resp.data.users);
    setPagination(
      produce((draft) => {
        draft.total = resp.data.total;
      })
    );
  };

  useEffect(() => {
    getUsers(pagination);
  }, []);

  const onPaginationChange = (pgn) => {
    setPagination(pgn);
    getUsers(pgn);
  };

  const handleOk = async (user, roles) => {
    user.roles = roles ? roles.join(",") : "";
    await api.put(`/api/users/${editedUser.uuid}`, user);
    setUsers(
      users.map((usr) => {
        if (usr.uuid === editedUser.uuid) {
          usr = { ...usr, ...user };
        }
        return usr;
      })
    );
    setIsModalVisible(false);
  };

  const toggleDisabled = async (user) => {
    user.disabled = !user.disabled;
    await api.put(`/api/users/${user.uuid}`, user);
    setUsers(
      users.map((usr) => {
        if (usr.uuid === user.uuid) {
          usr.disabled = user.disabled;
        }
        return usr;
      })
    );
  };

  const handleSubmitProfile = async (profile) => {
    setIsProfileVisible(false);
  };

  const columns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",

      render: (text, record) => (
        <a
          onClick={() => {
            setEditedUser(record);
            setIsProfileVisible(true);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      render: (roles) => (
        <>
          {roles.split(",").map((role) => {
            let color = role === "admin" ? "volcano" : "green";
            return (
              <Tag color={color} key={role}>
                {role}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Disabled",
      key: "disabled",
      dataIndex: "disabled",
      render: (disabled) => <span>{disabled ? "true" : "false"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <span></span>

          <Button
            type="link"
            size="small"
            icon={<CloseCircleOutlined />}
            onClick={() => toggleDisabled(record)}
          />
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedUser(record);
              setIsModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>Users</h1>
      <EditUserModal
        item={editedUser}
        modalTitle="Edit User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <Profile
        item={editedUser?.profile}
        modalTitle="Edit Profile"
        visible={isProfileVisible}
        onOk={handleSubmitProfile}
        onCancel={() => {
          setIsProfileVisible(false);
        }}
      />
      <Table
        columns={columns}
        rowKey="uuid"
        pagination={pagination}
        loading={loading}
        onChange={onPaginationChange}
        dataSource={users}
      />
    </>
  );
};

export default Users;
