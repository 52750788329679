import {
  BgColorsOutlined,
  CloseSquareOutlined,
  CopyOutlined,
  EditOutlined,
  PlusSquareOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Button,
  Switch,
  Card,
  Col,
  Collapse,
  Popconfirm,
  Radio,
  Row,
  Typography,
} from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { copyDay, copyMeal, fetchAlimentsAsync } from "./dietsSlice";
import EditNameDescriptionModal from "./EditNameDescriptionModal";
import EditNoteModal from "./EditNoteModal";
import MealAliment from "./MealAliment";

const DietEditor = ({ item, onClose }) => {
  const dispatch = useDispatch();
  const copiedDay = useSelector((state) => state.diets.day);
  const copiedMeal = useSelector((state) => state.diets.meal);
  const [diet, setDiet] = useState({});
  const aliments = useSelector((state) => state.diets.aliments);
  const [options, setOptions] = useState([]);
  const [editType, setEditType] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [editedObj, setEditedObj] = useState(null);
  const [expandMeals, setOpenMeals] = useState([]);
  const { Panel } = Collapse;
  const { Title, Text } = Typography;

  useEffect(() => {
    setOptions(aliments.map((alm) => ({ value: alm.id, label: alm.name })));
  }, [aliments]);

  useEffect(() => {
    dispatch(fetchAlimentsAsync());
  }, []);

  useEffect(() => {
    if (item) {
      if (!item.id) {
        setEditedObj({
          name: "",
          description: "",
        });
        setEditType("diet");
        setIsEditModalVisible(true);
      } else {
        setDiet({ ...item });
      }
    }
  }, [item]);

  const handleAlimentChange = async (mealId, oldId, newId, quantity) => {
    if (newId !== "please select") {
      const resp = await api.put(`/api/diets/${diet.id}/mealaliment`, {
        mealId,
        oldId,
        newId,
        quantity,
      });

      setDiet(resp.data);
    }
  };

  const handleAlimentDelete = async (mealId, alimentId) => {
    if (alimentId !== "please select") {
      const resp = await api.delete(
        `/api/diets/${diet.id}/mealaliment/${mealId}/${alimentId}`
      );

      setDiet(resp.data);
    }
  };

  const handleAlimentAdd = async (meal, dayId, mealId) => {
    if (getOptions(meal).length === 0) {
      return;
    }
    setDiet(
      produce((draft) => {
        for (const day of draft.days) {
          if (day.id === dayId) {
            for (const meal of day.meals) {
              if (meal.id === mealId) {
                if (!meal.aliments) {
                  meal.aliments = [];
                }
                meal.aliments.push({
                  id: "please select",
                  meal_aliment: { quantity: 0 },
                });
                break;
              }
            }
            break;
          }
        }
      })
    );
  };

  const handleNote = async (changedObj) => {
    switch (editType) {
      case "meal":
        await api.put(`/api/diets/meal/${changedObj.id}`, {
          note: changedObj.note,
        });
        break;
      case "diet":
        await api.put(`/api/diets/${changedObj.id}`, {
          note: changedObj.note,
        });
        break;
    }
    setDiet(
      produce((draft) => {
        if (editType === "diet" && draft.id === changedObj.id) {
          draft.note = changedObj.note;
        } else {
          for (const day of draft.days) {
            if (day.id === changedObj.dayId) {
              for (const meal of day.meals) {
                if (meal.id === changedObj.id) {
                  meal.note = changedObj.note;
                  break;
                }
              }
              break;
            }
          }
        }
      })
    );
    setEditedObj(null);
    setIsNoteModalVisible(false);
  };

  const handleEdit = async (changedObj) => {
    switch (editType) {
      case "diet":
        if (changedObj.id) {
          await api.put(`/api/diets/${diet.id}`, changedObj);
          setDiet(
            produce((draft) => {
              draft.name = changedObj.name;
              draft.description = changedObj.description;
              draft.calories = changedObj.calories;
            })
          );
        } else {
          const resp = await api.post(`/api/diets`, changedObj);
          setDiet(resp.data);
        }
        break;
      case "day":
        if (changedObj.id) {
          await api.put(`/api/diets/day/${changedObj.id}`, {
            name: changedObj.name,
          });
          setDiet(
            produce((draft) => {
              for (const day of draft.days) {
                if (day.id === changedObj.id) {
                  day.name = changedObj.name;
                  day.description = changedObj.description;
                  break;
                }
              }
            })
          );
        } else {
          const resp = await api.post("/api/diets/day", changedObj);
          setDiet(
            produce((draft) => {
              if (!diet.days) {
                draft.days = [resp.data];
              } else {
                draft.days.push(resp.data);
              }
            })
          );
        }
        break;
      case "meal":
        if (changedObj.id) {
          await api.put(`/api/diets/meal/${changedObj.id}`, {
            name: changedObj.name,
          });
          setDiet(
            produce((draft) => {
              for (const day of draft.days) {
                if (day.id === changedObj.dayId) {
                  for (const meal of day.meals) {
                    if (meal.id === changedObj.id) {
                      meal.name = changedObj.name;
                      meal.description = changedObj.description;
                      break;
                    }
                  }
                  break;
                }
              }
            })
          );
        } else {
          const resp = await api.post("/api/diets/meal", changedObj);
          setDiet(
            produce((draft) => {
              for (const day of draft.days) {
                if (day.id === changedObj.dayId) {
                  if (!day.meals) {
                    day.meals = [];
                  }
                  day.meals.push(resp.data);
                  break;
                }
              }
            })
          );
        }
        break;
      default:
        console.log("UNKNOWN TYPE: ", editType, changedObj);
    }
    setEditedObj(null);
    setEditType("");
    setIsEditModalVisible(false);
  };

  const handleDayDelete = async (dayId) => {
    const resp = await api.delete(`/api/diets/${diet.id}/day/${dayId}`);
    setDiet(resp.data);
  };

  const handleMealDelete = async (dayId, mealId) => {
    const resp = await api.delete(`/api/diets/${diet.id}/meal/${mealId}`);
    setDiet(resp.data);
  };

  const mealInfo = (meal, alimentId) => {
    if (!meal.alimentsInfo) {
      return 0;
    }
    return meal.alimentsInfo.filter((info) => info.alimentId === alimentId)[0];
  };

  const handleDietCategoryChange = async (newCat) => {
    await api.put(`/api/diets/${diet.id}`, { category: newCat });
    setDiet({ ...diet, category: newCat });
  };

  const handlePasteDay = async () => {
    const resp = await api.post(`/api/diets/${diet.id}/pasteday`, copiedDay);
    setDiet(resp.data);
  };

  const handlePasteMeal = async (dayId) => {
    const resp = await api.post(
      `/api/diets/${diet.id}/${dayId}/pastemeal`,
      copiedMeal
    );
    setDiet(resp.data);
  };

  // filters the aliments already attached to the meal
  const getOptions = (meal) => {
    if (!meal?.aliments) {
      return options;
    }
    const alimentIds = meal.aliments.map((a) => a.id);
    return options.filter((o) => !alimentIds.includes(o.value));
  };

  const toggleMealPanel = (checked) => {
    if (checked && diet?.days) {
      let mealIdList = [];
      diet.days
        .filter((days) => days.meals.length > 0)
        .forEach((element) =>
          element.meals.forEach((meal) => mealIdList.push(meal.id))
        );
      setOpenMeals(Array.from(mealIdList, (id) => "ml" + id));
    } else {
      setOpenMeals([]);
    }
  };

  return (
    <>
      <EditNameDescriptionModal
        item={editedObj}
        modalTitle="Edit"
        visible={isEditModalVisible}
        onOk={handleEdit}
        onCancel={() => {
          setEditedObj(null);
          setEditType("");
          setIsEditModalVisible(false);
        }}
      />
      <EditNoteModal
        item={editedObj}
        modalTitle="Edit note"
        visible={isNoteModalVisible}
        onOk={handleNote}
        onCancel={() => {
          setEditedObj(null);
          setEditType("");
          setIsNoteModalVisible(false);
        }}
      />
      <Card
        key={`diet${diet.name}`}
        title={
          <>
            <Title level={4}>
              {diet.name} ({diet.calories})
              <Button
                type="link"
                size="small"
                key="editDietNote"
                title="Edit diet note"
                icon={<FileTextOutlined />}
                onClick={() => {
                  setEditedObj(diet);
                  setEditType("diet");
                  setIsNoteModalVisible(true);
                }}
              />
            </Title>
            <Text type="secondary">{diet.description}</Text>
          </>
        }
        extra={[
          <Radio.Group
            onChange={(e) => {
              handleDietCategoryChange(e.target.value);
            }}
            buttonStyle="solid"
            size="small"
            title="Change diet type"
            value={diet.category}
          >
            <Radio.Button value="lowCarb">Low Carb</Radio.Button>
            <Radio.Button value="carbCycling">Carb Cycling</Radio.Button>
            <Radio.Button value="433">4.3.3</Radio.Button>
            <Radio.Button value="veganTotal">Vegan Total</Radio.Button>
            <Radio.Button value="ovaLacto">Ova-Lacto</Radio.Button>
            <Radio.Button value="ovaLactoPeste">Ova-Lacto-Peste</Radio.Button>
            <Radio.Button value="autoimune">Autoimune</Radio.Button>
          </Radio.Group>,
          <Button
            type="link"
            size="small"
            title="Edit diet"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedObj(diet);
              setEditType("diet");
              setIsEditModalVisible(true);
            }}
          />,
          <>
            {copiedDay && (
              <Button
                type="link"
                size="small"
                title="Paste day"
                icon={<BgColorsOutlined />}
                onClick={handlePasteDay}
              />
            )}
          </>,
          <Button
            type="link"
            size="small"
            title="Add day"
            icon={<PlusSquareOutlined />}
            onClick={() => {
              setEditedObj({ name: "", description: "", dietId: diet.id });
              setEditType("day");
              setIsEditModalVisible(true);
            }}
          />,
        ]}
        actions={[
          <Button key="close" onClick={onClose}>
            Close
          </Button>,
        ]}
      >
        <Switch
          checkedChildren="Close all"
          unCheckedChildren="Expand all"
          onChange={toggleMealPanel}
          disabled={
            !(
              Object.keys(diet).length > 0 &&
              diet.days?.filter((days) => days.meals?.length > 0).length > 0
            )
          }
        />
        <Row wrap>
          {diet?.days &&
            diet.days
              .slice()
              .sort((a, b) => a.id - b.id)
              .map((day) => (
                <Col>
                  <Card
                    size="small"
                    key={`day${day.id}`}
                    bordered="false"
                    title={
                      <>
                        <Title level={5}>
                          {day.name} ({Number(day.calories).toFixed(2)})
                        </Title>
                        <Text type="secondary">{day.description}</Text>
                        <Text type="secondary">
                          <div>
                            Carbs: {Number(day.carbs).toFixed(2)} (
                            {(
                              (day.carbs * 400) /
                              (day.carbs * 4 + day.proteins * 4 + day.fats * 9)
                            ).toFixed(2)}
                            %)
                          </div>
                          <div>
                            Proteins: {Number(day.proteins).toFixed(2)} (
                            {(
                              (day.proteins * 400) /
                              (day.carbs * 4 + day.proteins * 4 + day.fats * 9)
                            ).toFixed(2)}
                            %)
                          </div>
                          <div>
                            Fats: {Number(day.fats).toFixed(2)} (
                            {(
                              (day.fats * 900) /
                              (day.carbs * 4 + day.proteins * 4 + day.fats * 9)
                            ).toFixed(2)}
                            %)
                          </div>
                        </Text>
                      </>
                    }
                    extra={[
                      <Button
                        type="link"
                        size="small"
                        title="Edit day"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setEditedObj(day);
                          setEditType("day");
                          setIsEditModalVisible(true);
                        }}
                      />,
                      <Popconfirm
                        title="Are you sure to delete this day?"
                        onConfirm={() => handleDayDelete(day.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="link"
                          size="small"
                          title="Delete day"
                          danger
                          icon={<CloseSquareOutlined />}
                        />
                      </Popconfirm>,
                      <Button
                        type="link"
                        size="small"
                        icon={<CopyOutlined />}
                        title="Copy day"
                        onClick={() => {
                          dispatch(copyDay(day));
                        }}
                      />,
                      <>
                        {copiedMeal && (
                          <Button
                            type="link"
                            size="small"
                            title="Paste meal"
                            icon={<BgColorsOutlined />}
                            onClick={() => {
                              handlePasteMeal(day.id);
                            }}
                          />
                        )}
                      </>,
                      <Button
                        type="link"
                        size="small"
                        icon={<PlusSquareOutlined />}
                        title="Add meal"
                        onClick={() => {
                          setEditedObj({
                            name: "",
                            description: "",
                            dayId: day.id,
                          });
                          setEditType("meal");
                          setIsEditModalVisible(true);
                        }}
                      />,
                    ]}
                    style={{ width: 400 }}
                  >
                    <Collapse activeKey={expandMeals} onChange={setOpenMeals}>
                      {day?.meals &&
                        day.meals
                          .slice()
                          .sort((a, b) => a.id - b.id)
                          .map((meal) => (
                            <Panel
                              size="small"
                              header={<span>{meal.name}</span>}
                              key={`ml${meal.id}`}
                              bordered="false"
                              extra={[
                                <Button
                                  type="link"
                                  size="small"
                                  key="editMeal"
                                  title="Edit meal"
                                  icon={<EditOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditedObj(meal);
                                    setEditType("meal");
                                    setIsEditModalVisible(true);
                                  }}
                                />,
                                <Popconfirm
                                  key="confirmDeleteMeal"
                                  title="Are you sure to delete this meal?"
                                  onConfirm={() =>
                                    handleMealDelete(day.id, meal.id)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    type="link"
                                    size="small"
                                    key="deleteMeal"
                                    title="Delete meal"
                                    danger
                                    icon={<CloseSquareOutlined />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                </Popconfirm>,
                                <Button
                                  type="link"
                                  size="small"
                                  key="copyMeal"
                                  title="Copy meal"
                                  icon={<CopyOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(copyMeal(meal));
                                  }}
                                />,
                                <Button
                                  type="link"
                                  size="small"
                                  key="editMealNote"
                                  title="Edit meal note"
                                  icon={<FileTextOutlined />}
                                  onClick={() => {
                                    setEditedObj(meal);
                                    setEditType("meal");
                                    setIsNoteModalVisible(true);
                                  }}
                                />,
                                <Button
                                  type="link"
                                  size="small"
                                  key="addAliment"
                                  title="Add aliment"
                                  icon={<PlusSquareOutlined />}
                                  disabled={getOptions(meal).length === 0}
                                  onClick={(e) => {
                                    handleAlimentAdd(meal, day.id, meal.id);
                                    e.stopPropagation();
                                  }}
                                ></Button>,
                              ]}
                            >
                              <Title level={5}>{meal.description}</Title>
                              {meal?.aliments &&
                                meal.aliments
                                  .slice()
                                  .sort(
                                    (a, b) =>
                                      mealInfo(meal, a.id)?.id -
                                      mealInfo(meal, b.id)?.id
                                  )
                                  .map((aliment) => (
                                    <MealAliment
                                      key={`alim${aliment.id}`}
                                      item={aliment}
                                      quantity={
                                        mealInfo(meal, aliment.id)?.quantity
                                      }
                                      options={getOptions(meal)}
                                      onChange={(newId, quantity) => {
                                        handleAlimentChange(
                                          meal.id,
                                          aliment.id,
                                          newId,
                                          quantity
                                        );
                                      }}
                                      onDelete={() => {
                                        handleAlimentDelete(
                                          meal.id,
                                          aliment.id
                                        );
                                      }}
                                    />
                                  ))}
                            </Panel>
                          ))}
                    </Collapse>
                  </Card>
                </Col>
              ))}
        </Row>
      </Card>
    </>
  );
};

export default DietEditor;
