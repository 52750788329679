import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Button, Row, Popconfirm, Space, Table } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDietsAsync,
  deleteDietAsync,
  createDietAsync,
  updateDietAsync,
} from "./dietsSlice";
import DietEditor from "./DietEditor";

const Diets = () => {
  const loading = useSelector((state) => state.diets.loading);
  const dietsCount = useSelector((state) => state.diets.dietsCount);
  const diets = useSelector((state) => state.diets.diets);
  const dispatch = useDispatch();
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [editedDiet, setEditedDiet] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setPagination(
      produce((draft) => {
        draft.total = dietsCount;
      })
    );
  }, [dietsCount]);

  useEffect(() => {
    dispatch(fetchDietsAsync(pagination));
  }, []);

  const onPaginationChange = (pgn) => {
    setPagination(pgn);
    dispatch(fetchDietsAsync(pgn));
  };

  const handleOk = async (diet) => {
    if (!diet.id) {
      // create diet
      dispatch(createDietAsync(diet));
    } else {
      // update diet
      dispatch(updateDietAsync(diet));
    }
    setIsEditorVisible(false);
  };

  const handleDelete = async (diet) => {
    dispatch(deleteDietAsync(diet));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "Calories",
      key: "calories",
      dataIndex: "calories",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Popconfirm
            title="Are you sure to delete this diet?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditedDiet(record);
              setIsEditorVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <h1>Diets</h1>
      {isEditorVisible && (
        <DietEditor
          key={`de${editedDiet.name}`}
          item={editedDiet}
          modalTitle="Edit Diet"
          onOk={handleOk}
          onCancel={() => {
            setIsEditorVisible(false);
          }}
          onClose={() => {
            setEditedDiet(null);
            setIsEditorVisible(false);
            dispatch(fetchDietsAsync(pagination));
          }}
        />
      )}
      <Row justify="end">
        <Button
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => {
            setEditedDiet({});
            setIsEditorVisible(true);
          }}
        >
          New Diet
        </Button>
      </Row>
      <Table
        columns={columns}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={onPaginationChange}
        dataSource={diets}
      />
    </>
  );
};

export default Diets;
