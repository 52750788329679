import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { Button, Col, Input, Popconfirm, Row, Select } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";

const MealAliment = ({ item, quantity, options, onChange, onDelete }) => {
  const [aliment, setAliment] = useState({});
  const [qty, setQty] = useState(quantity);

  useEffect(() => {
    const a = { ...item };
    a.edit = a.id === "please select";
    if (a.id === "please select") {
      setQty(0);
    }
    setAliment(a);
  }, [item]);

  const handleSubmit = () => {
    if (aliment.id !== "please select") {
      onChange(aliment.id, qty);
      setAliment(
        produce((draft) => {
          draft.edit = false;
        })
      );
    } else {
      onDelete();
    }
  };

  return (
    <Row key={aliment.id}>
      <Col span={16}>
        {aliment.edit ? (
          <Select
            showSearch
            size="small"
            style={{ width: "100%" }}
            placeholder="aliment"
            value={{ value: aliment.id, label: aliment.name }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(id) => {
              setAliment(
                produce((draft) => {
                  draft.id = id;
                })
              );
            }}
            options={options}
          />
        ) : (
          <span
            style={{ width: "100%" }}
            onClick={() => {
              setAliment(
                produce((draft) => {
                  draft.edit = !aliment.edit;
                })
              );
            }}
          >
            {aliment.name}
          </span>
        )}
      </Col>
      <Col span={5}>
        {aliment.edit ? (
          <Input
            size="small"
            value={qty}
            onPressEnter={handleSubmit}
            onChange={(e) => {
              setQty(e.target.value);
            }}
          />
        ) : (
          <span
            onClick={() => {
              setAliment(
                produce((draft) => {
                  draft.edit = !aliment.edit;
                })
              );
            }}
          >{`${qty}g`}</span>
        )}
      </Col>
      <Col span={3}>
        {!aliment.edit ? (
          <Popconfirm
            title="Are you sure to delete this aliment?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="link"
              title="Remove aliment"
              size="small"
              icon={<CloseSquareOutlined />}
            />
          </Popconfirm>
        ) : (
          <Button
            type="link"
            size="small"
            title="Submit aliment"
            icon={<CheckSquareOutlined />}
            onClick={handleSubmit}
          />
        )}
      </Col>
    </Row>
  );
};

export default MealAliment;
