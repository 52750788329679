import PostCarousel from "../blog/PostCarousel";

const Home = () => {
  return (
    <>
      <PostCarousel title="Before and After" category="beforeAndAfter" key="" />
      <PostCarousel title="Blog" category="blog" />
    </>
  );
};

export default Home;
