import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import api, {clearTokens, getToken, setTokens} from "../../services/api";

const initialState = {
  user: null,
  status: "",
};

const initAuth = () => {
  const token = getToken();
  if (token) {
    const user = jwt_decode(token);
    if (user && user.role) {
      user.roles = user.roles.split(",");
    }
    initialState.user = user;
  }
};

initAuth();


export const loginAsync = createAsyncThunk("auth/login", async (params) => {
  let resp = null;
  if (params.kind === "Login") {
    resp = await api.post("/api/auth/login", params);
  } else {
    resp = await api.post("/api/auth/register", params);
  }
  const token = resp.data.token;
  const refresh = resp.data.refresh;
  setTokens(token,refresh, true);

  const user = jwt_decode(token);
  if (user && user.role) {
    user.roles = user.roles.split(",");
  }
  // The value we return becomes the `fulfilled` action payload
  return { user };
});

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  await api.get("/api/auth/logout");
});

export const authSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { user } = action.payload;
        state.user = user;
        state.status = "loggedin";
      })
      .addCase(logoutAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        clearTokens();
        state.user = null;
        state.status = "loggedout";
      });
  },
});

export default authSlice.reducer;
