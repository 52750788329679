import * as dayjs from "dayjs";
import {
  Button,
  Form,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Radio,
  Modal,
  Space,
} from "antd";
import { InfoCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { fetchBestDietAsync } from "../diets/dietsSlice";
import { useDispatch, useSelector } from "react-redux";

const Profile = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const dispatch = useDispatch();
  const bestDiet = useSelector((state) => state.diets.bestDiet);
  const [form] = Form.useForm();
  const [profile, setProfile] = useState({});
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    if (item) {
      //console.log("PROFILE: ", item);
      setProfile({ ...item });
    }
  }, [item]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const onBirthDateChange = (date) => {
    if (date) {
      form.setFieldsValue({
        age: [dayjs().diff(dayjs(date), "year")],
      });
      onValuesChange();
    }
  };

  const onValuesChange = () => {
    computeBMR();
    computeTDEE();
    computeCalories();
  };

  const onFieldsChange = (field) => {
    //console.log('Field: ', field);
  };

  const onSelectChange = (value, option) => {
    setSelection(value);
  };

  const getDependentFields = () => {
    switch (form.getFieldValue("objective")) {
      case "weightLoss":
        return (
          <>
            <Form.Item label="Rata de slabire" name="weightLossRate">
              <Select placeholder="Choose a selection">
                <Select.Option value="wieghtLossFast">-7 kg</Select.Option>
                <Select.Option value="wieghtLossNorm">-5 kg</Select.Option>
                <Select.Option value="wieghtLossSlow">-2 kg</Select.Option>
              </Select>
            </Form.Item>
          </>
        );
      case "hypertrophy":
        return (
          <>
            <Form.Item label="Rata de ingrasare" name="weightGainRate">
              <Select placeholder="Choose a selection">
                <Select.Option value="wieghtGainFast">+4 kg</Select.Option>
                <Select.Option value="wieghtGainSlow">+2 kg</Select.Option>
              </Select>
            </Form.Item>
          </>
        );
      case "toning":
      default:
        // needs better handling of empty vertical space
        return (
          <>
            <Form.Item>
              <Input disabled={true} bordered={false} readOnly={true} />
            </Form.Item>
          </>
        );
    }

    return null;
  };

  const computeBMR = () => {
    let age = form.getFieldValue("age");
    let height = form.getFieldValue("height");
    let weight = form.getFieldValue("weight");
    let gender = form.getFieldValue("sex");
    let bmr = 0;

    if (age > 0 && height > 0 && weight > 0 && gender !== undefined) {
      bmr = 10 * weight + 6.25 * height - 5 * age + 5;
      if (gender === "F") {
        bmr = bmr - 166;
      }

      form.setFieldsValue({
        bmr: [bmr],
      });
    }

    return bmr;
  };

  const computeTDEE = () => {
    let activityLevel = form.getFieldValue("activity");
    let bmr = computeBMR();
    let tdee = 0;
    if (bmr > 0 && activityLevel !== undefined) {
      let coef = 1;
      switch (activityLevel) {
        case "sedentary":
          coef = 1.25;
          break;
        case "active":
          coef = 1.3;
          break;
        case "hyperactive":
          coef = 1.4;
          break;
        default:
          coef = 1;
      }
      tdee = bmr * coef;
      form.setFieldsValue({
        tdee: [tdee],
      });
    }

    return tdee;
  };

  const objectiveCoefDict = {
    undefined: -1,
    wieghtLossSlow: 90 / 100,
    wieghtLossNorm: 85 / 100,
    wieghtLossFast: 75 / 100,
    wieghtGainSlow: 105 / 100,
    wieghtGainFast: 110 / 100,
  };

  const computeCalories = () => {
    let objective = form.getFieldValue("objective");
    let tdee = computeTDEE();

    let coef = 1;
    let cat = undefined;
    if (tdee > 0 && objective !== undefined) {
      switch (objective) {
        case "weightLoss":
          coef = objectiveCoefDict[form.getFieldValue("weightLossRate")];
          cat = "lowCarb";
          break;
        case "toning":
          coef = 1;
          cat = "carbCycling";
          break;
        case "hypertrophy":
          coef = objectiveCoefDict[form.getFieldValue("weightGainRate")];
          cat = "433";
          break;
        default:
          coef = 1;
      }

      if (coef < 0) {
        form.setFieldsValue({
          calNeed: [0],
        });
      } else {
        form.setFieldsValue({
          calNeed: [tdee * coef],
        });
      }

      console.log(cat);
      if (cat !== undefined) {
        console.log("setting value");
        form.setFieldsValue({
          category: [cat],
        });
      }
    }
  };

  return (
    <Modal
      forceRender
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(profile)}
      onCancel={onCancel}
      getContainer={false}
    >
      <Form
        form={form}
        name="profile-form"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
        labelCol={{ span: 25 }}
        wrapperCol={{ span: 20 }}
      >
        <Space style={{ display: "flex" }} align="baseline">
          <Form.Item
            name="name"
            label="Nume"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input placeholder="Nume" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="nume@mail.com" />
          </Form.Item>
        </Space>
        <Space style={{ display: "flex" }} align="baseline">
          <Form.Item
            name="password"
            label="Parola"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirmare Parola"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Space>
        <Divider orientation="left">Profile</Divider>
        <Space style={{ display: "flex" }} align="baseline">
          <Form.Item
            name="sex"
            label="Sex"
            rules={[{ required: true, message: "Please pick an item!" }]}
          >
            <Radio.Group>
              <Radio value="F">Femeie</Radio>
              <Radio value="M">Barbat</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="birthDate"
            label="Data Nasterii"
            initialValue={dayjs("01/01/2000")}
            rules={[{ required: true, message: "Please select a birth date!" }]}
          >
            <DatePicker onChange={onBirthDateChange} />
          </Form.Item>
        </Space>
        <Space>
          <Form.Item
            name="age"
            label="Varsta"
            //initialValue={dayjs().diff(dayjs('01/01/2000'), 'year')}
            style={{ display: "inline-block" }}
          >
            <InputNumber
              disabled={true}
              addonAfter="ani"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Space>
        <Space>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          />
          <Form.Item
            name="height"
            label="Inaltime"
            style={{ display: "inline-block" }}
            rules={[
              {
                type: "number",
                min: 100,
                max: 220,
                message: "Please input a value between 100 and 220!",
              },
              {
                required: true,
                message: "Please input your height!",
              },
            ]}
          >
            <InputNumber
              addonAfter="cm"
              style={{ width: "100%" }}
              step={10}
              min={0}
            />
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          />
          <Form.Item
            name="weight"
            label="Greutate"
            style={{ display: "inline-block" }}
            rules={[
              {
                type: "number",
                min: 10,
                max: 250,
                message: "Please input a value between 10 and 250!",
              },
              {
                required: true,
                message: "Please input your weight!",
              },
            ]}
          >
            <InputNumber
              addonAfter="kg"
              style={{ width: "100%" }}
              step={10}
              min={0}
            />
          </Form.Item>
        </Space>
        <Form.Item
          label="Activitate"
          tooltip={{
            title: "How would you describe your normal daily activities?",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Space>
            <Form.Item
              name="activity"
              noStyle
              rules={[
                { required: true, message: "Activity Level is required" },
              ]}
              requiredMark={true}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="sedentary">
                  Sedentar
                  <Tooltip title="Spend most of the day sitting (e.g. bank teller, desk job)">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
                <Radio.Button value="active">
                  Moderat Activ
                  <Tooltip title="Spend a good part of the day doing some physical activity (e.g. food server, postal carrier)">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
                <Radio.Button value="hyperactive">
                  Foarte Activ
                  <Tooltip title="Spend most of the day doing heavy physical activity (e.g. bike messenger, carpenter)">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Obiectiv">
          <Space>
            <Form.Item
              name="objective"
              noStyle
              rules={[{ required: true, message: "Objective is required" }]}
            >
              <Radio.Group buttonStyle="solid" onChange={onSelectChange}>
                <Radio.Button value="weightLoss">
                  Pierdere in greutate
                  <Tooltip title="weight loss description">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
                <Radio.Button value="toning">
                  Tonifiere
                  <Tooltip title="toning description">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
                <Radio.Button value="hypertrophy">
                  Hipertrofie
                  <Tooltip title="hypertrophy description">
                    {" "}
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Space>
        </Form.Item>

        {getDependentFields()}

        <Divider orientation="left">Results</Divider>

        <Form.Item name="bmr" label="BMR">
          <InputNumber
            addonAfter="cal/zi"
            style={{ width: "100%" }}
            //disabled={true}
            readOnly={true}
          />
        </Form.Item>

        <Form.Item name="tdee" label="TDEE">
          <InputNumber
            addonAfter="cal/zi"
            style={{ width: "100%" }}
            //disabled={true}
            readOnly={true}
          />
        </Form.Item>

        <Form.Item name="calNeed" label="Necesar">
          <InputNumber
            addonAfter="cal/zi"
            style={{ width: "100%" }}
            //disabled={true}
            readOnly={true}
          />
        </Form.Item>

        <Form.Item name="category" label="Category">
          <Input
            style={{ width: "100%" }}
            //disabled={true}
            readOnly={true}
          />
        </Form.Item>

        <h1>
          {bestDiet?.id} {bestDiet?.name}
        </h1>

        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              console.log();
              dispatch(
                fetchBestDietAsync({
                  category: form.getFieldValue("category")[0],
                  calories: form.getFieldValue("calNeed")[0],
                })
              );
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Profile;
