import { FileOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Checkbox, Input, Modal, Radio, Space, Select } from "antd";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ImageUploader from "./ImageUploader";

const EditPostModal = ({ modalTitle, item, visible, onCancel, onOk }) => {
  const [post, setPost] = useState({});
  const [tags, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const { TextArea } = Input;

  useEffect(() => {
    if (item) {
      setPost({ ...item });
      setTags(item.tags ? item.tags.split(",") : []);
    }
  }, [item]);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      maskClosable={false}
      onOk={() => onOk(post, images, tags)}
      onCancel={onCancel}
    >
      <ImageUploader
        images={post.images}
        updateImages={(images) => {
          setImages(images);
        }}
      />
      <Space direction="vertical">
        <Input
          prefix={<FileOutlined />}
          placeholder="title"
          value={post.title}
          onChange={(e) => {
            setPost({ ...post, title: e.target.value });
          }}
        />
        <Radio.Group
          onChange={(e) => {
            setPost({ ...post, category: e.target.value });
          }}
          value={post.category}
        >
          <Radio.Button value="blog">Blog</Radio.Button>
          <Radio.Button value="beforeAndAfter">BeforeAndAfter</Radio.Button>
        </Radio.Group>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Please add tags"
          value={tags}
          onChange={(tgs) => setTags(tgs)}
        />
        <Input
          prefix={<VideoCameraOutlined />}
          placeholder="video"
          value={post.video}
          onChange={(e) => {
            setPost({ ...post, video: e.target.value });
          }}
        />
        <TextArea
          showCount
          autoSize
          rows="4"
          value={post.text}
          onChange={(e) => {
            setPost({ ...post, text: e.target.value });
          }}
        />
        <ReactMarkdown>{post.text}</ReactMarkdown>
        <Checkbox
          checked={post.frontPage}
          onChange={(e) => {
            setPost({ ...post, frontPage: e.target.checked });
          }}
        >
          Front page
        </Checkbox>
      </Space>
    </Modal>
  );
};
export default EditPostModal;
