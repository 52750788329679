import { Card, Pagination } from "antd";
import { produce } from "immer";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Post from "./Post";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const getPosts = async () => {
    const resp = await api.get(
      `/api/posts/read?disabled=false&page=${pagination.current}&limit=${pagination.pageSize}`
    );
    setPosts(resp.data.posts);
    setPagination(
      produce((draft) => {
        draft.total = resp.data.total;
      })
    );
  };

  useEffect(() => {
    getPosts();
  }, []);

  const onPaginationChange = (pgn) => {
    setPagination(
      produce((draft) => {
        draft.current = pgn;
      })
    );
    getPosts();
  };

  return (
    <>
      <h2>Blog</h2>
      {posts.map((p) => (
        <Card key={p.uuid}>
          <Post key={p.uuid} post={p} />
        </Card>
      ))}
      <Pagination {...pagination} onChange={onPaginationChange} />
    </>
  );
};

export default Blog;
