import { Card, Col, Image, Row } from "antd";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";

const contentStyle = {
  height: "450px",
  textAlign: "center",
  background: "#f0f2f5",
  overflow: "hidden",
};

const cardStyle = {
  background: "#f0f2f5",
};

const Story = ({ post }) => {
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (post.images) {
      setImages(post.images.split(","));
    }
  }, [post]);

  return (
    <div style={contentStyle}>
      <Row justify="center" wrap>
        <Col span="12">
          {images.length > 0 && (
            <>
              <Image
                preview={{ visible: false }}
                width="100%"
                src={`/media/uploads/${images[0]}`}
                onClick={() => setVisible(true)}
              />
              <div style={{ display: "none" }}>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {images.map((img) => (
                    <Image src={`/media/uploads/${img}`} key={img} />
                  ))}
                </Image.PreviewGroup>
              </div>
            </>
          )}
          {post.video && (
            <ReactPlayer
              controls
              width="100%"
              height="450px"
              url={post.video}
            />
          )}
        </Col>
        <Col span={post.images || post.video ? 12 : 24}>
          <Card style={cardStyle} title={post.title} bordered={false}>
            <ReactMarkdown>{post.text}</ReactMarkdown>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Story;
