const Aliment = ({ aliment }) => {
  return (
    <>
      <p>
        {aliment.name} {aliment.quantity}g
      </p>
    </>
  );
};

export default Aliment;
